import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

export const MARKETING_HUB_STATUS_QUERY_KEY = "marketing-hub-status";

const useMarketingHubStatusAPI = () =>
  useQuery(
    MARKETING_HUB_STATUS_QUERY_KEY,
    () => reactQueryGet(internalApi.marketingHubStatus.show()),
    {
      notifyOnChangeProps: "tracked",
    },
  );

interface UseMarketingHubStatusContextReturn {
  refetch: () => Promise<any>;
  isLoading: boolean;
  data: any;
}

export const useMarketingHubStatusContext =
  (): UseMarketingHubStatusContextReturn => {
    const { data, refetch, isLoading } = useMarketingHubStatusAPI();

    return {
      refetch,
      isLoading,
      data,
    };
  };
